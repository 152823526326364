import { pacingParser } from "./pacing";
export default async function parseHandler(data, reportId) {
    const parsers = {
        pacing: pacingParser
    };
    if (!data) {
        return Promise.reject("No data to parse");
    }
    if (!parsers[reportId]) {
        return Promise.reject(`No parser found for reportId ${reportId}`);
    }
    return parsers[reportId](data);
}
