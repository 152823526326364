import { css } from "@emotion/react";
import { kitText } from "../../styles";

const item = css`
  padding: var(--list-item-padding);
  width: 100%;
  background-color: var(--color-background-primary);
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-2);
  align-items: center;
  border-radius: 2px;
  ${kitText.variant.body}
`;
export const styles = {
  listWrapper: css`
    gap: var(--list-gap);
    display: flex;
    flex-direction: column;
    overflow: auto;
    .kit-ListHeader {
      .kit-Input {
        border-radius: var(--spacing-4);
      }
    }
  `,
  scrollArea: css`
    overflow: auto;
    max-height: var(--list-max-height);
    height: var(--list-height);
    min-height: var(--list-min-height);
  `,
  itemsList: css`
    display: flex;
    flex-direction: column;
    gap: var(--list-gap);
    padding: 0;
    list-style: none;
    margin: 0;
    li {
      list-style: none;
    }
    .kit-ListHeader {
      position: sticky;
      top: -2px;
      z-index: 1;
      background-color: var(--color-background-primary);
    }
  `,
  itemWrapper: css`
    --icon-fill-color: var(--color-splash-primary);
    --color-background-selected: #e7e9ef;
    border: none;
    user-select: none;
    width: 100%;
    list-style: none;
    padding: 0 var(--list-padding-x, var(--spacing-4));
    .clear-item-icon * {
      opacity: 0;
    }
    &:focus,
    &:focus .item-inner {
      outline: none;
    }
  `,
  item,
  infoItem: css`
    ${item}
    padding: 0 var(--list-padding-x, var(--spacing-4));
    gap: var(--spacing-8);
    justify-content: flex-start;
    align-items: center;
  `,
  isClickable: css`
    cursor: pointer;
    &:hover .item-inner {
      background-color: var(--color-background-tertiary);
    }
    &:focus .item-inner {
      background-color: var(--color-background-tertiary);
    }
  `,
  iconAndLabel: css`
    min-width: 0;
    width: 100%;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-start;
    align-items: center;
    gap: var(--spacing-8);
  `,
  itemContent: css`
    ${kitText.variant.body};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-grow: 1;
    display: flex;
  `,
  selected: css`
    cursor: default;
    .item-inner {
      background-color: var(--color-background-selected);
    }
    &:hover,
    &:focus {
      .item-inner {
        background-color: var(--color-background-selected);
      }
    }
  `,
  isDisabled: css`
    cursor: not-allowed;
    pointer-events: none;
    .item-inner {
      background: var(--color-background-tertiary);
      color: var(--color-tertiary);
      --icon-fill-color: var(--color-tertiary);
    }
  `,
  clearableSelected: css`
    &:hover,
    &:focus {
      cursor: pointer;
      .clear-item-icon * {
        opacity: 1;
      }
    }
  `,
  listHeader: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: var(--spacing-8);
    padding: 0 var(--list-padding-x) var(--spacing-8);
  `,
  infoSection: css`
    --icon-fill-color: var(--color-secondary);
    ${item}
    justify-content: flex-start;
    gap: var(--spacing-8);
  `,
  sectionTitle: css`
    ${kitText.variant.body}
    ${kitText.style.bold}
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--color-tertiary);
    padding: var(--spacing-8) var(--spacing-12) var(--spacing-4);
    top: calc(var(--spacing-8) * -1);
    position: sticky;
    z-index: 1;
    background-color: var(--color-background-primary);
  `,
  divider: css`
    width: 100%;
    background-color: var(--color-border-secondary);
    margin: 2px 0;
    height: 1px;
    .kit-ListHeader + & {
      display: none;
    }
  `,
  selectedCount: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-12) var(--spacing-12) var(--spacing-8);
    ${kitText.variant.body}
  `
};
