import lookHandler from "./look";
import parseHandler from "./parse";
import reportHandler from "./report";
export class DataHandler {
    constructor(sdk) {
        this.sdk = sdk;
        this.look = lookHandler.bind(this);
        this.parse = parseHandler.bind(this);
        this.report = reportHandler.bind(this);
    }
}
