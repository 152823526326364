import { ReactElement, useMemo, useRef } from "react";
import List from "..";
import { defaultLookupId, defaultLookupLabel } from "../../utils";
import {
  ListWithDataProps,
  WrappedListComponentType,
  WrappedListComponentProps,
  BaseListProps
} from "../types";
import { DoNotCare, isIdType } from "../../../types";
import { styles } from "../styles";
import Icon from "../../Icon";
import { isListWithSelectableItems } from "./withSelectableItems";

/**
 * Simple type guard to determine if a List component props has data.
 */
export function isListWithData<T>(
  props: BaseListProps | ListWithDataProps<T>
): props is ListWithDataProps<T> {
  return "data" in props && !!props.data;
}

/**
 * Higher Order Component that adds data handling to a list component.
 * @param WrappedComponent The kit List component to wrap.
 * @returns A new component that can handle data, with the initial + additional props
 */
export function listWithData<T>(WrappedComponent: WrappedListComponentType<T>) {
  /**
   * List component that includes rendering items from data.
   * @param props The props for the list component. {@link ListWithDataProps}
   */
  function Component(props: ListWithDataProps<T>) {
    const {
      data,
      sections = [],
      lookupSection = (item: DoNotCare) => item?.section || "",
      itemProps: {
        label: lookupLabel = defaultLookupLabel,
        id: lookupId = defaultLookupId,
        onClick: onItemClick = undefined,
        onFocus: onItemFocus = undefined,
        onBlur: onItemBlur = undefined,
        onKeyDown: onItemKeyDown = undefined,
        onKeyUp: onItemKeyUp = undefined,
        isDisabled,
        prefix: itemPrefix
      } = {},
      children = (item: T) => lookupLabel(item) || lookupId(item),
      selected: { hideSelected, pinned, cannotClear, fixed } = {},
      isVirtualized
    } = props;

    const { selectedItems = [], selectedIds = [] } =
      props as WrappedListComponentProps<T>;

    const { items, sectionIndices } = useMemo(() => {
      const res = {
        items: [] as Array<T | ReactElement>,
        sectionIndices: [] as number[]
      };

      // if selected items are pinned, add them to the top of the list
      if (pinned && !hideSelected && selectedItems?.length) {
        res.items.push(...selectedItems);
      }

      // if no sections are provided, add all items to the list
      if (!sections.length) {
        const items =
          pinned || hideSelected
            ? data.filter(item => !selectedIds.includes(lookupId(item)!))
            : data;
        res.items.push(...items);
        return res;
      }

      // create a map of sections with their items in order to group items by section
      const sectionMap = new Map(
        sections.map(section =>
          isIdType(section)
            ? [section, { id: section, title: section, items: [] as T[] }]
            : [section.id, { ...section, items: [] as T[] }]
        )
      );

      // add items to their section in sectionMap
      data.forEach(item => {
        if (selectedIds.includes(lookupId(item)!) && (hideSelected || pinned)) {
          return;
        }
        const sectionKey = (lookupSection && lookupSection(item)) || "";
        const sectionItem = sectionMap.get(sectionKey)!;
        if (sectionItem) {
          sectionItem.items.push(item);
        }
      });

      // flatten sectionMap into a list of items
      [...sectionMap.values()].forEach((section, i) => {
        const { id, title, items } = section;
        if (!items.length) {
          return;
        }
        // add divider between sections
        if (i > 0 || (!id && pinned && selectedIds.length)) {
          res.items.push(<List.Divider key={`divider-${i}`} />);
        }
        // add section title element
        if (id) {
          res.sectionIndices.push(res.items.length);
          res.items.push(
            <List.SectionTitle key={id} id={id}>
              {title}
            </List.SectionTitle>
          );
        }
        // add section items
        res.items.push(...(items as T[]));
      });
      return res;
    }, [data, sections, pinned, selectedItems, selectedIds]);

    const nextFocus = useRef<number>(-1);

    const handleNextFocus = (index: number) => {
      if (index === nextFocus.current + 1) {
        nextFocus.current = -1;
      }
      let nextIndex = index;
      for (let i = index + 1; i < items.length; i++) {
        const item = items[i];
        if (item && typeof item === "object" && !("props" in item)) {
          nextIndex = i;
          break;
        }
      }
      nextFocus.current = nextIndex;
    };

    const itemElements = useMemo(() => {
      const elements = items.map((item, i) => {
        if (item && typeof item === "object" && "props" in item) {
          return item;
        }
        return (
          <List.Item
            id={lookupId(item)}
            key={lookupId(item)}
            onClick={onItemClick ? e => onItemClick(e, item) : undefined}
            onFocus={onItemFocus ? e => onItemFocus(e, item) : undefined}
            onKeyDown={onItemKeyDown ? e => onItemKeyDown(e, item) : undefined}
            onKeyUp={onItemKeyUp ? e => onItemKeyUp(e, item) : undefined}
            onBlur={onItemBlur ? e => onItemBlur(e, item) : undefined}
            isSelected={
              selectedIds.includes(lookupId(item)!) ||
              fixed?.has(lookupId(item)!)
            }
            cannotClear={cannotClear}
            label={lookupLabel(item)?.toString()}
            disabled={isDisabled?.(item) || fixed?.has(lookupId(item)!)}
            prefix={itemPrefix?.(item)}
            selectable={isListWithSelectableItems(props)}
            onFocusAfterSelect={() => handleNextFocus(i)}
            focusOnMount={nextFocus.current === i}
            section={lookupSection(item)}
          >
            {children(item)}
          </List.Item>
        );
      });
      if (!data.length && !props?.error) {
        elements.push(
          <div
            key="no-results"
            css={styles.infoSection}
            data-testid="list-info-no-results"
          >
            <Icon name="SearchEmpty" size="small" />
            <span>No results found</span>
          </div>
        );
      }
      return elements;
    }, [items, data.length]);

    return (
      <WrappedComponent
        {...(props as DoNotCare)}
        items={items}
        sectionIndices={sectionIndices}
        isVirtualized={data.length ? isVirtualized : false}
      >
        {itemElements}
      </WrappedComponent>
    );
  }
  return Component;
}
