import { DateTime } from "luxon";
const formatDateFilterForLooker = (dates) => dates.map((d) => DateTime.fromJSDate(d).toFormat("yyyy/MM/dd")).join(" to ");
export default async function lookHandler(lookId = "", filters = {}) {
    try {
        const res = await this.sdk.looker.sdk.ok(this.sdk.looker.sdk.look(lookId, "query"));
        const { query } = res;
        if (!query) {
            throw new Error("No query found");
        }
        const newQueryBody = {
            ...query,
            filters: {
                ...query.filters,
                ...(filters && filters.dates
                    ? { "vw_dim_lineitem.flt_analysis_interval": formatDateFilterForLooker(filters.dates) }
                    : {})
            },
            client_id: null,
            id: null
        };
        const newQuery = await this.sdk.looker.sdk.ok(this.sdk.looker.sdk.create_query(newQueryBody));
        if (!newQuery.id) {
            throw new Error("No query id found");
        }
        return await this.sdk.looker.sdk.ok(this.sdk.looker.sdk.run_query({
            query_id: newQuery.id,
            result_format: "json"
        }));
    }
    catch (error) {
        return Promise.reject(error);
    }
}
