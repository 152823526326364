import React, { forwardRef, MutableRefObject, useEffect, useRef } from "react";
import { css } from "@emotion/react";
import { styles } from "../styles";
import { Display } from "../../Icons";
import Icon from "../../Icon";
import { DoNotCare } from "../../types";
import { ListItemProps } from "../types";

/**
 * Utility function to focus the next item in a list.
 * @param event - The event that triggered the focus change.
 */
const focusNextItem = (
  event: React.KeyboardEvent<HTMLElement> | React.MouseEvent<HTMLElement>
) => {
  let next: DoNotCare = event.currentTarget?.nextElementSibling;
  for (let i = 0; i < 4; i++) {
    if (next?.getAttribute("role") !== "option") {
      next = next?.nextElementSibling;
    }
  }
  if (!next) {
    next = event.currentTarget?.parentElement?.firstElementChild;
    if (next?.getAttribute("role") !== "option") {
      next = next?.nextElementSibling;
    }
  }
  next?.focus();
};

/**
 * Utility function to focus the previous item in a list.
 * @param event - The event that triggered the focus change.
 */
const focusPreviousItem = (
  event: React.KeyboardEvent<HTMLElement> | React.MouseEvent<HTMLElement>
) => {
  let prev: DoNotCare = event.currentTarget?.previousElementSibling;
  for (let i = 0; i < 4; i++) {
    if (prev?.getAttribute("role") !== "option") {
      prev = prev?.previousElementSibling;
    }
  }
  if (!prev || prev === event.currentTarget) {
    prev =
      event.currentTarget?.parentElement?.parentElement?.parentElement?.querySelector(
        "input"
      );
  }
  prev?.focus();
};

/**
 * `List.Item` is used to render a single item in a list.
 * @example
 * ```tsx
 * <List>
 *   <List.Item>Thing #1</List.Item>
 *   <List.Item onClick={doSomething}>Thing #2</List.Item>
 * </List>
 * ```
 */
const ListItem = forwardRef(function ListItem(
  {
    id,
    label,
    children = label,
    prefix,
    cannotClear = false,
    isSelected = false,
    disabled = false,
    selectable = false,
    section,
    focusOnMount = false,
    onClick,
    onKeyDown,
    onKeyUp,
    onFocus,
    onBlur,
    onFocusAfterSelect
  }: ListItemProps,
  ref: MutableRefObject<HTMLLIElement | null>
) {
  let role: "listitem" | "option" | "group" = "listitem";
  if (section) {
    role = "group";
  } else if (selectable) {
    role = "option";
  }

  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    if (disabled || !id || (cannotClear && isSelected)) {
      return;
    }
    event.currentTarget.blur();
    onClick?.(event);
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (id && ["Enter", " "].includes(event.key)) {
      focusNextItem(event);
      onClick?.(event as DoNotCare);
      setTimeout(() => onFocusAfterSelect?.());
    }
    if (["ArrowDown", "ArrowUp"].includes(event.key)) {
      event.preventDefault();
      event.stopPropagation();
      if (event.key === "ArrowDown") {
        focusNextItem(event);
      }
      if (event.key === "ArrowUp") {
        focusPreviousItem(event);
      }
    }
    onKeyDown?.(event);
  };

  const itemRef = useRef<HTMLLIElement | null>(null);

  const interactiveProps =
    role === "listitem"
      ? {}
      : {
          onClick: handleOnClick,
          onKeyDown: handleOnKeyDown,
          onKeyUp,
          onFocus,
          onBlur,

          tabIndex: 0
        };

  useEffect(() => {
    if (focusOnMount) {
      itemRef.current?.focus();
    }
  }, []);

  return (
    <li
      className="kit-ListItem"
      ref={current => {
        const propsRef = ref;
        if (propsRef) {
          propsRef.current = current;
        }
        itemRef.current = current;
      }}
      role={role}
      id={typeof id === "string" ? id : undefined}
      {...(!!section && { ["aria-labelledby"]: section })}
      {...interactiveProps}
      css={[
        styles.itemWrapper,
        !disabled && (onClick || selectable) && styles.isClickable,
        isSelected && styles.selected,
        !cannotClear && isSelected && !disabled && styles.clearableSelected
      ]}
      aria-label={label}
      aria-disabled={disabled}
      aria-selected={isSelected}
    >
      <div className="item-inner" css={[styles.item]}>
        <div css={[styles.iconAndLabel]}>
          {!!prefix && (
            <Display size="small">
              {typeof prefix === "string" ? (
                <Icon name={prefix} size="fill" />
              ) : (
                prefix
              )}
            </Display>
          )}

          <div css={[styles.itemContent]}>{children}</div>
        </div>

        {!cannotClear && isSelected && (
          <div
            className="clear-item-icon"
            tabIndex={-1}
            css={css`
              display: contents;
            `}
          >
            <Icon name="Circled.X" fill="var(--color-secondary)" />
          </div>
        )}
      </div>
    </li>
  );
});

ListItem.displayName = "List.Item";
export default ListItem;
