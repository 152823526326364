import React, { ComponentProps, useRef } from "react";
import { useSearcher } from "../../../hooks";
import {
  BaseListProps,
  ListProps,
  ListWithDataProps,
  WrappedListComponentType
} from "../types";
import Input from "../../Input";

/**
 * Simple type guard to determine if a List component props include searching
 */
export function isListWithSearch<T>(
  props: BaseListProps | ListWithDataProps<T>
): props is ListProps<T> & {
  searchProps: ListWithDataProps<T>["searchProps"];
} {
  const { searchProps, isSearchable } = (props || {}) as ListWithDataProps<T>;
  if (searchProps && isSearchable !== false) {
    return true;
  }
  if (isSearchable) {
    return true;
  }
  return false;
}

/**
 * Higher Order Component that adds searching functionality to a list component.
 * @param WrappedComponent The kit List component to wrap.
 * @returns A new component that can handle searching, with the initial + additional props
 */
export function listWithSearch<T>(
  WrappedComponent: WrappedListComponentType<T>
) {
  /**
   * List component that includes searching functionality.
   * @param props The props for the list component. {@link ListWithDataProps}
   */
  const Component = <Item extends T>(props: ListWithDataProps<Item>) => {
    const {
      isSearchable,
      searchProps: search,
      data,
      containerRef: propsContainerRef,
      header
    } = props;
    const {
      keys = typeof data[0] === "string" ? [] : ["name", "id"],
      isLoading = false,
      debounceMs,
      onAsyncQuery
    } = search || {};

    const containerRef = useRef<HTMLElement | null>(null);

    const {
      filteredItems,
      isLoading: searchIsLoading,
      setQuery,
      query
    } = useSearcher({
      items: data as any[],
      disabled: isSearchable === false || (!isSearchable && !search),
      keys,
      isLoading,
      debounceMs,
      onAsyncQuery
    });

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case "Enter": {
          !!e.currentTarget.value &&
            containerRef.current
              ?.querySelector<HTMLElement>(
                "[aria-selected=false][aria-disabled=false]"
              )
              ?.click();
          break;
        }
        case "ArrowDown":
        case "Tab": {
          e.preventDefault();
          e.stopPropagation();
          if (e.shiftKey && e.key === "Tab") {
            break;
          }
          containerRef.current
            ?.querySelector<HTMLOptionElement>(
              "li[aria-selected]:not([aria-disabled=true])"
            )
            ?.focus();
          break;
        }
        default:
          break;
      }
      search?.onKeyDown?.(e);
    };

    return (
      <WrappedComponent
        {...(props as ComponentProps<typeof WrappedComponent>)}
        data={filteredItems}
        containerRef={(current: HTMLElement | null) => {
          containerRef.current = current;
          propsContainerRef?.(current);
        }}
        isLoading={props.isLoading || searchIsLoading}
        header={
          <>
            <span className="list-search-input">
              <Input
                value={query}
                onChange={setQuery}
                onClick={e => e.stopPropagation()}
                onKeyDown={handleOnKeyDown}
                prefix="Search"
                type="text"
                fill
                placeholder={search?.placeholder || "Search..."}
              />
            </span>

            {header}
          </>
        }
      />
    );
  };
  return Component;
}
